import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Keys from 'src/constants/helper';

type IPrivateRouter = {
  children: React.ReactNode;
};

const PrivateRouter: FC<IPrivateRouter> = ({ children }) => {
  const location = useLocation();
  const getToken = localStorage.getItem(Keys.JWT_TOKEN);
  return getToken ? (
    <>{children}</>
  ) : (
    <Navigate
      to='/auth?type=login'
      replace={true}
      state={{ from: location.pathname }}
    />
  );
};

export default PrivateRouter;
